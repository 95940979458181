import { Box, Typography, Stack, Card, Button } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import Link from '@mui/material/Link';
import { useForm } from 'react-hook-form';
import Page from '#/components/shared/ui/Page';

import useLocales from '#/hooks/useLocales';
import Image from '#/components/shared/ui/Image';
import { usePreventiveCheckUpContact } from '#/api/userQueries';
import { FormProvider, RHFTextField } from '#/components/shared/hook-form';
import { Banner } from '#/components/pages/Landing/components/banner';
import { scroller } from 'react-scroll';

export default function PreventiveCheckUp() {
  const { translate, currentLang } = useLocales();

  const handleJoinWaitList = () => {
    scroller.scrollTo('vorsorgecheck', {
      smooth: true,
    });
  };

  const pageTitle = translate('metadata.preventiveCheckUp.title');
  const pageDescription = translate('metadata.preventiveCheckUp.description');
  const pageKeywords = translate('metadata.preventiveCheckUp.keywords', {
    returnObjects: true,
  });

  return (
    <Page
      title={pageTitle}
      description={pageDescription}
      keywords={pageKeywords}
    >
      <Stack direction="column" spacing={20}>
        <Banner
          intro={translate('preventiveCheckUp.mainBanner.intro')}
          heading={translate('preventiveCheckUp.mainBanner.title')}
          subHeading={translate('preventiveCheckUp.mainBanner.subtitle')}
          image="https://storage.gutgeregelt.ch/assets/main-landings/banners/vorsorgecheck.webp"
          cta={
            <Stack direction="row" spacing={2} alignItems="center">
              <Button variant="contained" onClick={handleJoinWaitList}>
                <Typography>
                  {translate('preventiveCheckUp.mainBanner.buttonLabel')}
                </Typography>
              </Button>
              <Typography sx={{ fontStyle: 'italic' }}>
                {translate('preventiveCheckUp.allInfos')}
              </Typography>
            </Stack>
          }
        />
        <TheChallenges />
        <Box
          sx={{
            px: {
              xs: 2,
              md: 15,
            },
            maxWidth: 1264,
            margin: '0 auto',
            alignSelf: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3" component="h2" color="primary.main">
            {translate('preventiveCheckUp.liabilityRisk')}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ marginBottom: 6, textAlign: 'center', maxWidth: 1264 }}
          >
            {translate('preventiveCheckUp.liabilityRiskSubtitle')}
          </Typography>
          <Image
            src={`https://storage.gutgeregelt.ch/assets/main-landings/sections/${
              currentLang?.value === 'de'
                ? 'haftungsrisiko'
                : `haftungsrisiko_${currentLang.value}`
            }.webp`}
            alt="Haftungsrisiko"
            sx={{
              width: {
                xs: '320px',
                md: '640px',
              },
            }}
          />
        </Box>
        <Benefits />
        <ContactForm />
      </Stack>
    </Page>
  );
}

const CHALLENGE_KEYS = ['increasedLifeExpectancy', 'inflation', 'ahv'] as const;
const BENEFIT_KEYS = ['transparency', 'liability', 'documentation'] as const;

const TheChallenges = () => {
  const { translate } = useLocales();
  return (
    <Box
      sx={{
        px: {
          xs: 2,
          md: 15,
        },
        maxWidth: 1264,
        margin: '0 auto',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h3" component="h2" color="primary.main">
        {translate('preventiveCheckUp.theChallenges.title')}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ marginBottom: 6, textAlign: 'center' }}
      >
        {translate('preventiveCheckUp.theChallenges.description')}
      </Typography>
      <Box
        sx={{
          display: {
            xs: 'block',
            md: 'flex',
          },
          gap: 3,
        }}
      >
        {CHALLENGE_KEYS.map((key, i) => (
          <Card
            key={i}
            sx={{
              paddingY: 3,
              paddingX: 2,
              width: {
                xs: '100%',
                md: '33%',
              },
            }}
          >
            <Typography
              variant="h4"
              component="h3"
              color="primary.darker"
              sx={{ marginBottom: 2 }}
            >
              {translate(`preventiveCheckUp.theChallenges.cards.${key}.title`)}
            </Typography>
            <Typography variant="body1">
              {translate(
                `preventiveCheckUp.theChallenges.cards.${key}.description`
              )}
            </Typography>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

const Benefits = () => {
  const { translate } = useLocales();
  return (
    <Box
      sx={{
        px: {
          xs: 2,
          md: 15,
        },
        maxWidth: 1264,
        margin: '0 auto',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h3" component="h2" color="primary.main">
        {translate('preventiveCheckUp.benefits.title')}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ marginBottom: 6 }}
        textAlign="center"
      >
        {translate('preventiveCheckUp.benefits.description')}
      </Typography>
      <Box
        sx={{
          display: {
            xs: 'block',
            md: 'flex',
          },
          flex: 1,
          gap: 3,
        }}
      >
        {BENEFIT_KEYS.map((key, i) => (
          <Card
            key={i}
            sx={{
              paddingY: 3,
              paddingX: 2,
              width: {
                xs: '100%',
                md: '33%',
              },
            }}
          >
            <Typography
              variant="h4"
              component="h3"
              color="primary.darker"
              sx={{ marginBottom: 2 }}
            >
              {translate(`preventiveCheckUp.benefits.cards.${key}.title`)}
            </Typography>
            <Typography variant="body1">
              {translate(`preventiveCheckUp.benefits.cards.${key}.description`)}
            </Typography>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

const ContactForm = () => {
  const ContactSchema = Yup.object().shape({
    name: Yup.string().required('validations.user.name'),
    email: Yup.string()
      .email('validations.user.invalidEmail')
      .required('validations.user.email'),
    mobile_phone: Yup.string().required(
      'validations.user.mobile_phone.required'
    ),
  });

  const defaultValues = {
    name: '',
    email: '',
    mobile_phone: '',
  };

  const methods = useForm({
    resolver: yupResolver(ContactSchema),
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const { translate } = useLocales();

  const { mutateAsync: contact, isLoading } = usePreventiveCheckUpContact();

  const onSubmit = async (data: any) => {
    await contact(data).then(() => {
      reset(defaultValues);
    });
  };

  const ctaText = translate('preventiveCheckUp.contactForm.cta');
  const ctaParts = ctaText.split('info@gutgeregelt.ch');

  return (
    <Box
      id="vorsorgecheck"
      sx={{
        px: {
          xs: 2,
          md: 15,
        },
        maxWidth: 1264,
        margin: '0 auto',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        sx={{ textAlign: 'center' }}
        variant="h3"
        component="h2"
        color="primary.main"
      >
        {translate('preventiveCheckUp.contactForm.title')}
      </Typography>
      <Box
        sx={{
          display: {
            xs: 'block',
            md: 'flex',
          },
          alignItems: 'center',
        }}
      >
        <Card
          sx={{
            padding: 2,
            margin: 2,
            width: {
              xs: '100%',
              md: '50%',
            },
          }}
        >
          <Typography variant="subtitle1">
            {translate('preventiveCheckUp.contactForm.description')}
            <p>
              {ctaParts[0]}
              <Link href="mailto:info@gutgeregelt.ch">info@gutgeregelt.ch</Link>
              {ctaParts[1]}
            </p>
          </Typography>
        </Card>

        <Card
          sx={{
            padding: 2,
            margin: 2,
            width: {
              xs: '100%',
              md: '50%',
            },
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <RHFTextField
                name="name"
                placeholder={translate('global.formLabels.name')}
              />
              <RHFTextField
                name="email"
                placeholder={translate('global.formLabels.emailAddress')}
              />
              <RHFTextField
                name="mobile_phone"
                placeholder={translate('global.formLabels.mobilePhone')}
              />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                <Typography>{translate('global.formLabels.submit')}</Typography>
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Card>
      </Box>
    </Box>
  );
};
